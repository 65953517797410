import React, { Fragment, useEffect, useState } from "react";
import numbro from "numbro";
import {
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FeatureDTO from "./Interfaces/FeatureDTO";
import FeatureMetricDTO from "./Interfaces/FeatureMetricDTO";
import MostOrLeastUsed from "./Components/MostOrLeastUsed/MostOrLeastUsed";
import "./App.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    if (isLoading) {
      fetch("https://product-health-server.databased.com/usageData").then(
        async (res) => {
          setFeatures(await res.json());
          setIsLoading(false);
        }
      );
    }
  });

  function formatNumber(number: number | null, unit: string): string {
    if (number === null) {
      return "--";
    }
    return numbro(number).format({
      thousandSeparated: true,
      output: unit === "%" ? "percent" : unit === "$" ? "currency" : "number",
    });
  }

  return (
    <Paper className="container" elevation={2}>
      <Typography id="page-heading" variant="h4" component="h1">
        Product Health Report
      </Typography>
      {isLoading && <CircularProgress id="loadingSign" />}
      {!isLoading && (
        <Fragment>
          <MostOrLeastUsed features={features} reportType={'most'} title={'Most-Used Feature Summary'} />
          <MostOrLeastUsed features={features} reportType={'least'} title={'Least-Used Feature Summary'} />
          {features.map((feature: FeatureDTO) => {
          return (
            <Card
              key={`feature${feature.title}`}
              className="feature-card"
              elevation={2}
            >
              <CardContent>
                <Typography variant="h5" component="h2">
                  {feature.title}
                </Typography>
                <Typography variant="body1" component="p">
                  <strong>What counts as a "Usage"?:</strong>{" "}
                  {feature.singleUsageCriteria}
                </Typography>
                <Typography variant="body1" component="p">
                  <strong>Number of Usages during last 7 days:</strong>{" "}
                  {formatNumber(feature.usageL7, "")}
                </Typography>
                <Typography variant="body1" component="p">
                  <strong>Number of Usages during last 30 days:</strong>{" "}
                  {formatNumber(feature.usageL30, "")}
                </Typography>
                <Typography variant="body1" component="p">
                  <strong>Number of Usages during last 90 days:</strong>{" "}
                  {formatNumber(feature.usageL90, "")}
                </Typography>
                {feature.featureMetrics.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Additional Metrics</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>Performance</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>Target</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {feature.featureMetrics.map(
                        (featureMetric: FeatureMetricDTO) => {
                          return (
                            <TableRow key={featureMetric.description}>
                              <TableCell component="th" scope="row">
                                {featureMetric.description}
                              </TableCell>
                              <TableCell align="right">
                                {formatNumber(
                                  featureMetric.performance,
                                  featureMetric.unit
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {formatNumber(
                                  featureMetric.target,
                                  featureMetric.unit
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                )}
              </CardContent>
            </Card>
          );
        })}
        </Fragment>
      )
        }
    </Paper>
  )
}

export default App;
