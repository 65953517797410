import React from "react";
import {
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import FeatureDTO from '../../Interfaces/FeatureDTO'

type Props = {
  features: FeatureDTO[]
  reportType: string
  title: string
}

function MostOrLeastUsed(props: Props) {
  const { reportType, title } = props

  function getMostUsedLeastUsedReportInDays(days: number): string {
    const { features, reportType } = props

    if(days === 7) {
      const sortedUsageReportInLast7Days: FeatureDTO[] =  features.sort((a: FeatureDTO, b: FeatureDTO) => a.usageL7 < b.usageL7 ? -1 : 1)
      return reportType === 'least' ? sortedUsageReportInLast7Days[0].title : sortedUsageReportInLast7Days[sortedUsageReportInLast7Days.length - 1].title
    } 

    if(days === 30) {
      const sortedUsageReportInLast30Days: FeatureDTO[] =  features.sort((a: FeatureDTO, b: FeatureDTO) => a.usageL30 < b.usageL30 ? -1 : 1)
      return reportType === 'least' ? sortedUsageReportInLast30Days[0].title : sortedUsageReportInLast30Days[sortedUsageReportInLast30Days.length - 1].title
    }

    if(days === 90) {
      const mostUsedFeatureInLast90Days: FeatureDTO[] =  features.sort((a: FeatureDTO, b: FeatureDTO) => a.usageL90 < b.usageL90 ? -1 : 1)
      return reportType === 'least' ? mostUsedFeatureInLast90Days[0].title : mostUsedFeatureInLast90Days[mostUsedFeatureInLast90Days.length - 1].title
    }

    return ''
  }

  return (
    <Card className="feature-card">
        <CardContent>
          <Typography variant="h5" component="h2">
          {title}
          </Typography>
          <Typography>{`${reportType.charAt(0).toUpperCase()}${reportType.slice(1)}`}-used feature in last 7 days: <strong>{getMostUsedLeastUsedReportInDays(7)}</strong></Typography>
          <Typography>{`${reportType.charAt(0).toUpperCase()}${reportType.slice(1)}`}-used feature in last 90 days: <strong>{getMostUsedLeastUsedReportInDays(90)}</strong></Typography>
          <Typography>{`${reportType.charAt(0).toUpperCase()}${reportType.slice(1)}`}-used feature in last 30 days: <strong>{getMostUsedLeastUsedReportInDays(30)}</strong></Typography>
        </CardContent>
      </Card>
  )
}

export default MostOrLeastUsed;
